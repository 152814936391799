/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import { SEO, ParseContent } from 'components/shared'
import Breadcrumb from 'components/elements/BreadCrumb'
import Layout from 'components/layout/Layout'
import Calculator from 'components/related/Calculator'
import Plaatjie from '@ubo/plaatjie'

const Hero = styled.section`
  margin-top: -10px;

  @media screen and (max-width: 992px) {
    background-size: cover !important;
  }
`

const HeroContent = styled.div`
  padding-top: 155px;
  padding-bottom: 90px;
`

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h3 {
      font-size: 18px;
    }
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;

  @media (max-width: 992px) {
    max-height: 300px;
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
  pageContext,
}) => {
  const containerRef = useRef(null)

  useEffect(() => {
    // Inject the CSS
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href =
      'https://dealers.leasefinancier.nl/wp-content/plugins/dlf-lease-calculator/assets/css/main.css'
    document.head.appendChild(link)

    // Inject the div
    if (containerRef.current) {
      containerRef.current.innerHTML = `
        <div id="dlf-calculator"
          data-calculator-type="calculator"
          data-notification="info@kontado.nl"
          data-api-token=""
          data-position="right"
          data-company-name="company name"
          data-company-code="company code"
          data-form-bg-color="#ffffff"
          data-primary-color="#1d3660"
          data-secondary-color="#000000"
          data-form-border-radius="25"
          data-button-bg="#23c154"
          data-button-color="#ffffff"
          data-checkbox-bg="#ffffff"
          data-checkbox-selected-bg="#eb6209">
        </div>
      `
    }

    // Inject the script
    const script = document.createElement('script')
    script.src =
      'https://dealers.leasefinancier.nl/wp-content/plugins/dlf-lease-calculator/assets/js/main.js'
    script.async = true
    document.body.appendChild(script)

    // Cleanup on unmount
    return () => {
      document.head.removeChild(link)
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />

      <Hero
        className="hero-image-small"
        style={{
          backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`,
        }}
      >
        <HeroContent className="container px-3">
          <div className="mt-5 mb-3">
            <ParseContent
              content={`<h1><span style="color: rgb(255, 255, 255);"><strong>${title}</strong></span></h1>`}
            />
          </div>
          <Breadcrumb data={title} className="py-0" pageContext={pageContext} />
        </HeroContent>
      </Hero>

      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 col-lg-7">
            <Content content={acf.meta.description} />
          </div>
          <div className="col-md-6 col-lg-5">
            <Image src={acf.meta.image.localFile.publicURL} alt={title} />
          </div>
        </div>

        <div className="py-4 py-lg-5">
          <div ref={containerRef}></div>
        </div>

        <div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <Image
                src={acf.meta.image_calculator.localFile.publicURL}
                alt={title}
              />
            </div>
            <div className="col-md-6 col-lg-8 pt-4 pt-lg-0">
              <Content content={acf.meta.second_description} />
            </div>
          </div>
        </div>

        <div>
          <div className="row py-4 py-lg-5">
            <div className="col-md-6 col-lg-8 order-2 order-md-0">
              <Content content={acf.meta.extra_description} />
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
              <Image
                src={acf.meta.image_extra.localFile.publicURL}
                alt={title}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        meta {
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 750, quality: 100)
              }
            }
          }
          description
          second_description
          image_calculator {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 750, quality: 100)
              }
            }
          }
          image_extra {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 750, quality: 100)
              }
            }
          }
          extra_description
        }

        banner {
          background {
            localFile {
              publicURL
              childImageSharp {
                fluid(toFormat: WEBP, maxWidth: 2000, quality: 100) {
                  src
                }
              }
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
